.popular {
    position: relative;
  }
  
  .popular::after {
    content: "";
    width: 100%;
    height: 40vh;
    background: #eaf8f1;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  .popular img {
    width:100%;
    height:300px;
    /* width: 100%; /* Adjusted to 100% width for responsiveness */
    /* height: auto; Allow images to maintain their aspect ratio */
  
}
  
  .popular .card {
    margin: 10px;
  }
  
  .popular .item {
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin: 10px;
  }
  
  .popular .imgae {
    position: relative;
  }
  
  .popular .imgae i {
    position: absolute;
    bottom: 0;
    color: white;
    margin: 10px; /* Slightly reduced the margin for mobile */
  }
  
  .popular .imgae i label {
    margin-left: 5px; /* Slightly reduced the margin for mobile */
    font-weight: normal;
  }
  
  .popular .details {
    text-align: center;
  }
  
  .popular .rate {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    font-size: 13px;
    color: grey;
  }
  
  .popular .rate i {
    margin: 2px;
  }
  
  .popular .cards h2 {
    font-weight: 500;
    font-size: 20px;
    color: #31b675;
    position: relative;
    margin-bottom: 20px; /* Slightly reduced the margin for mobile */
  }
  
  
  
  .popular .cards h2::before {
    position: absolute;
    content: "";
    bottom: -15px; /* Adjusted for mobile */
    left: 35%;
    height: 1px;
    width: 100px; /* Slightly reduced the width for mobile */
    background: black;
  }
  
  .popular .cards h3 {
    color: #31b675;
    font-weight: 500;
  }
  
  .popular .cards h3 span {
    color: grey;
    font-weight: 300;
    font-size: 15px;
  }
  
  .popular .content {
    margin-bottom: 30px; /* Slightly reduced the margin for mobile */
  }
  
  /*----- control btn -------*/
  .popular .control-btn .next,
  .popular .control-btn .prev {
    top: 100%;
    background: none;
    margin-top: 10px; /* Added margin for separation */
  }
  
  .popular .control-btn .prev {
    left: 45%;
  }
  
  .popular .control-btn .next {
    right: 45%;
  }
  
  .popular .control-btn .prev i,
  .popular .control-btn .next i {
    background: rgba(0, 0, 0, 0.05);
    color: black;
    font-size: 15px;
    transition: 0.5s;
    padding: 5px; /* Adjusted padding for mobile */
    border-radius: 50%; /* Added rounded corners for mobile */
  }
  
  .popular .control-btn .prev i:hover,
  .popular .control-btn .next i:hover {
    background: #31b675;
    color: white;
    transition: 0.5s;
  }
  
  /*----- control btn -------*/
  
  @media screen and (max-width: 768px) {
    .popular .control-btn .prev {
      left: 40%;
    }
    .popular .control-btn .next {
      right: 42%;
    }
  
    .cards {
      display: flex;
      flex-direction: column;
    }
    .popular img {
        width:300px;
        height:200px;
        /* width: 100%; /* Adjusted to 100% width for responsiveness */
        /* height: auto; Allow images to maintain their aspect ratio */
      
    }
  }
  @media screen and (max-width: 460px){
    .popular img {
        width:100%;
        height:auto;
        /* width: 100%; /* Adjusted to 100% width for responsiveness */
        /* height: auto; Allow images to maintain their aspect ratio */
      
    }
      
  .popular .cards h2 {
    font-weight: 400;
    font-size: 15px;
    color: #31b675;
    position: relative;
    margin-bottom: 20px; /* Slightly reduced the margin for mobile */
  }
  .popular .cards h3 {
    color: #31b675;
    font-weight: 300;
  }
  .popular .control-btn .prev {
    left: 20%;
  }
  .popular .control-btn .next {
    right: 22%;
  }

  .popular .control-btn .next,
  .popular .control-btn .prev {
    top: 90%;
    background: none;
    margin-top: 10px; /* Added margin for separation */
  }
  .popular .cards h2::before {
    position: absolute;
    content: "";
    bottom: -15px; /* Adjusted for mobile */
    left: 30%;
    height: 1px;
    width: 40px; /* Slightly reduced the width for mobile */
    background: black;
  }

  }