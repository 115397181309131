.contact {
    padding: 60px 0;
  }
  
  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap:20px;
  }
  
  .main-content {
    flex: 1;
    padding: 10px;
    background-color: #f8f8f8;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    
  }
  .grid1{
    display: flex;
    flex-direction: column;
 
  }
  
  .main-content h2 {
    font-size: 24px;
    margin: 0 0 20px;
    color: #333;
  }
  
  .main-content p {
    color: #666;
  }
  
  .main-content form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  
  .input {
    margin-bottom: 20px;
  }
  
  .main-content span {
    display: block;
    font-size: 14px;
    color: #333;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  textarea {
    resize: vertical;
  }
  
  .primary-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .primary-btn:hover {
    background-color: #0056b3;
  }
  
  .side-content {
    flex: 1;
    padding: 10px;
    background-color: #f8f8f8;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .side-content .icon a img{
    color:black;
  }
  
  .main-content h3 {
    font-size: 20px;
    margin: 0 0 20px;
    color: #333;
  }
  
  .icon {
    margin-top: 20px;
  }
  
  .flex_space {
    display: flex;
    justify-content: space-between;
  }
  
  .main-content a {
    font-size: 24px;
    color: #333;
    margin: 0 10px;
  }
  .contact-icon {
    font-size: 24px;
    color: rgb(35, 3, 3);
    margin: 0 10px;
    display:flex;
    justify-content: flex-start;

  }
  .social-iconc{
    color: rgb(35, 3, 3);

  }
  .main-content img{
    color: rgb(35, 3, 3);
  }
  

  @media screen and (max-width: 700px){
    .container{
        display:flex;
        flex-direction: column;
    }
  }