/* Styles for mobile responsiveness */

.join-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: aliceblue;
    padding: 20px;
}

.j-text {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.j-btn {
    background-color: rgb(51, 2, 51);
    color: white;
    border: none;
    border-radius: 20px;
    padding: 20px 30px;
    font-size: 16px;
    cursor: pointer;
}

.join-content {
    display: flex;
    flex-direction: row;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
}

.join-img-div {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.join-img-div img {
    width: 400px;
    height: 300px;
    gap: 20px;
    border-radius: 20px;
}

@media screen and (max-width: 768px) {
    .join-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .join-img-div {
        display: flex;
        flex-direction: column;
        padding: 10px;
        gap: 10px;
    }
    
    .join-img-div img {
        width: 100%; /* Adjusted to be responsive */
        height: auto;
        border-radius: 20px;
        margin: 20px;
    }
    
    .j-text h1 {
        font-size: 30px;
    }
}
