Whoweare.css

.who-container {
    
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
  }
  
  .container-box {
    margin-top:90px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
    padding-top:20px;
    padding-bottom: 20px;
  
  }
  
  .img-box {

    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 20px;
  }
  
  .gala {
    width: 100%;
    height: auto;
    max-width: 600px;
    border-radius: 30px;
  }
  
  .text-box {
    width: 100%;
    padding: 20px;
    text-align: left;
  }
  
  h1 {
    font-size: 38px;
    font-weight: 900;
    margin-bottom: 10px;
    color: rgb(51, 2, 51);
  }
  
  p {
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 10px;
    color: rgb(61, 59, 59);
  }
  
  .www-btn {
    background-color: #31b675;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: 0.5s ease-in;
  }
  
  .www-btn:hover {
    background-color: #228e58;
    transition: 0.5s ease-in;
  }
  
  @media screen and (max-width: 460px) {
    .container-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 10px;
    }
    .img-box {
      margin: 0;
    }
  }
  