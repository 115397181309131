.slider {
    overflow: hidden;
    position: relative;
  }
  .join-btn {
    width: 200px;
    height: 50px;
    background-color: rgb(51, 2, 51);
    border: none;
    border-radius: 20px;
    color: whitesmoke;
    font-size: 16px;
    padding: 10px;
    cursor: pointer;
    transition: all 0.3s; /* Added transition for smoother hover effect */
  }
  
  .join-btn:hover {
    background-color: #31b675;
    color: black;
    transform: scale(1.1); /* Increase button size on hover */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* Add a box shadow on hover */
  }
  
  .slider img {
    width: 100%;
    height: 600px;
  }
  
  .control-btn .next {
    position: absolute;
    top: 40%;
    z-index: 10;
    right: 5%;
  }
  
  .control-btn .prev {
    position: absolute;
    top: 40%;
    z-index: 10;
    left: 5%;
  }
  
  .slide {
    opacity: 0;
    transition-duration: 1s ease;
  }
  
  .slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
  }
  
  /* Background overlay for slider and form */
  .slider::before,
  .slide-form::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.604); /* Adjust the opacity (0.5) as needed */
    z-index: 1;
  }
  .slide-text {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
     /* Adjust the opacity (0.5) as needed */
    z-index: 1;
  }
  
  /*--------form-----------*/
  .slide-text h2 {
    margin-top: 50px;
    font-size: 4rem;
    color: whitesmoke;
    padding: 20px;
  }
  
  .slide-text {
    position: absolute;
    top: 30%;
    left: 10%;
    width: auto;
    height: auto;
    border-radius: 20px;
    color: white;
    z-index: 2; /* Ensure the form is on top of the overlay */
  }

  .slide-text p{
    font-size: 1rem;
    color: whitesmoke;
    width:auto;
    padding: 20px;
    font-weight: bold;
  }
  
  .slide-form input {
    margin-top: 20px;
  }
  
  .slide-form .flex_space input:nth-child(odd) {
    margin-right: 20px;
  }
  
  .slide-form .submit {
    background: #31b675;
    color: white;
  }
  
  /*--------form-----------*/
  @media screen and (max-width: 768px) {
    .slider {
      height: 65vh;
    }
  
    .slide img {
      height: 65vh;
    }
  
    .slide-text {
      left: 11%;
      top: 18%;
      width: 90%;
      height: 55vh;
    }
  }

   /*--------form-----------*/
   @media screen and (max-width: 460px) {
    .slider {
      height: 45vh;
    }
    .join-btn{
        width:150px;
        height:50px;
    }
  
    .slide img {
      height: 45vh;
    }
  
    .slide-text {
      left: 11%;
    top: 20%;
    width: 90%;
    height: 55vh;
    }
    .slide-text h2{
        font-size: x-large;
        margin-top:-10px;
        padding:20px;
    }

    .slide-text p{
        font-size: 0.8rem;
        margin-top:-20px;
    }
    .control-btn i{
        width: 20px;
        height:20px;
        
    }
    .join-btn {
      width: 120px;
      height: 40px;
      background-color: rgb(51, 2, 51);
      border: none;
      border-radius: 20px;
      color: whitesmoke;
      font-size: 13px;
      padding: 10px;
      cursor: pointer;
      transition: all 0.3s; /* Added transition for smoother hover effect */
    }
  }
  