.video-description-container {
    margin-top:50px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    margin: 20px;
  
  }
  .video-text{
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    padding-top:50px;
  }
  .top-div{
    margin-top:100px;
    margin-left: 30px;
    margin-right:30px;

  }
  
  .video-description-text {
    flex: 1;
    max-width: 45%;
  }
  
  .video-description-text p {
    font-size: 16px;
    line-height: 1.5;
    color: #333;
  }
  .video-paragraph{
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    padding-top:10px;
    font-size: 28px !important;
  }
  
  .video-description-video {
    flex: 1;
    max-width: 45%;
  }
  
  video {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 768px) {
    .video-description-container {
      flex-direction: column; /* Stack elements vertically on small screens */
      gap: 15px; /* Slightly reduce the gap on mobile */
    }
  
    .video-description-text,
    .video-description-video {
      max-width: 100%; /* Allow each item to take full width */
    }
    .top-div{
        margin-top:10px;
        margin-left: 30px;
        margin-right:30px;
      }
  }