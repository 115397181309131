.gallery {
    margin-bottom: 50px; 
  }
  .gallery img {
    transition: 0.5s;
    width: 100%;
    height: 100%;
  }
  .galagrid{
    display:flex;
    flex-direction: wrap;
  }
    
  .gallery .items {
    padding: 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    text-align: center;
  }
  .gallery h3 {
    margin: 10px 0 10px 0;
    font-weight: 400;
  }
  .gallery .img {
    position: relative;
    overflow: hidden;
    width: 380px;
    height: 252px;
  }
  
  .iconn{
    position: absolute;
    top: 45%;
    left: 45%;
    z-index: 20;
    font-size: 40px;
    color: white;
    opacity: 0;
    transition: 0.5s ease-in-out;
    cursor:pointer;
  }
  .gallery .img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 380px;
    height: 252px;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: 0.5s ease-in-out;
  }
  .gallery .img::after {
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    width: 360px;
    height: 232px;
    z-index: 3;
    border: 1px solid #31b675;
    opacity: 0;
    transition: 0.5s ease-in-out;
  }
  
  .gallery .items:hover img {
    transform: scale(1.2);
    cursor: pointer;
  }
  .gallery .items:hover i,
  .gallery .items:hover .img::after,
  .gallery .items:hover .img::before {
    opacity: 1;
    cursor: pointer;
  }
  .popup {
    width: auto;
    height: 100vh;
    align-items: center;
    justify-content: center;
    top: 0;
    left: -30;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 8;
  }
  
  .hide {
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .popup-content {
    position: absolute;
    top: 15%;
    left: 10%;
  }
  .popup button {
    background-color: #fff;
    padding: 20px 20px;
    color: #000;
    position: absolute;
    top: 0%;
    right: -8.5%;
    font-weight: bold;
  }
  @media screen and (max-width: 768px) {
    .gallery .gcontainer {
      max-width: 100%;
    }
    .gallery .img {
      width: 100%;
      height: 200px;
    }
  
    .gallery .img::after {
      width: 300px;
      height: 180px;
    }
    .popup-content {
      padding: 50px;
      position: absolute;
      top: 10%;
      left: 0%;
      height:95vh;
    }
    .popup button {
      background-color: #fff;
      padding: 20px 20px;
      color: #000;
      position: absolute;
      top: -2%;
      right: 6.5%;
      font-weight: bold;
    }
    @media screen and (max-width: 508px){
      .gallery .gcontainer{
        display:flex;
        flex-direction: column;
      }
    }
  }