footer {
    background-color: rgb(51, 2, 51);
    color: white;
    margin-top: 50px;
    padding: 50px 0;
  }
  .box .flex_space {
    display:flex;
    justify-content:flex-start
  }
  footer .grid {
    grid-template-columns: repeat(4, 1fr);
  }
  footer h2 {
    margin-bottom: 20px;
    letter-spacing: 2px;
  }
  footer p {
    font-size: 13px;
    opacity: 0.6;
    font-weight: 300;
    letter-spacing: 1px;
    color:white;
  }
  footer .icon i {
    color: black;
  }
  footer ul li a {
    opacity: 0.7;
    color: white;
    font-size: 13px;
  }
  footer ul li {
    margin-bottom: 10px;
  }
  footer .post li {
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(63, 62, 62);
  }
  footer .post li:nth-last-child(1) {
    border-bottom: none;
  }
  footer .post label,
  footer .post span {
    opacity: 0.5;
    margin-right: 10px;
    font-size: 13px;
    margin-bottom: 20px;
  }
  footer input {
    background: none;
    border: 1px solid rgb(63, 62, 62);
    border-radius: 5px;
    margin: 10px 0;
  }
  footer .primary-btn {
    padding: 20px 20px;
    border-radius: 0px;
    text-align: center;
  }
  .legal {
    text-align: center;
    padding: 20px;
    background-color: rgb(51, 2, 51);
    border-top: 1px solid rgb(63, 62, 62);
    color: white;
  }
  .legal p {
    opacity: 0.5;
  }
  @media screen and (max-width: 768px) {
    footer .grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media screen and (max-width: 400px) {
    footer .grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }