.service-container{
    background-color: whitesmoke;
    align-items: center;
    justify-content: center;
    display:flex;
    flex-direction: column;
}

.service-header{
    margin-top:40px;
    display:flex;
    flex-direction: column;
    width:auto;
    align-items: center;
    justify-content: center;
    gap:20px;


}

.service-header h1{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    font-weight: 500;
    color: rgb(51, 2, 51);
    font-family:Impact, Haettenschweiler, sans-serif;

}

.service-header p{
    display: flex;
    align-items: center;
    justify-content: center;
    width:700px;
    color:rgb(72, 62, 62);
    font-size: 16px;
    text-align: center;

    
}

.service-down{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:20px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.service-item{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:10px;
    width: auto;
    height: auto;
    padding: 20px;
}
.service-item h1{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    font-weight: 500;
    color: rgb(51, 2, 51);
    font-family:Impact, Haettenschweiler, sans-serif;
}
.service-item h1:hover{
    color: rgb(14, 1, 14);
    font-size: 3.5rem;
    font-weight: 500;
    font-family:Impact, Haettenschweiler, sans-serif;
    transition: 0.5s ease-in-out;
}

.service-item p{
    display: flex;
    align-items: center;
    justify-content: center;
    width:700px;
    color:rgb(72, 62, 62);
    font-size: 16px;
    text-align: center;
    width:300px;
}
@media screen and (max-width: 768px){
.service-down{
    display:flex;
    flex-direction: column;
}

}

@media screen and (max-width: 430px){
    .service-header p{
        width:auto;
        padding:20px;
    }
    .service-header h1{
        text-align: center;
    }

    .service-item h1{
        text-align: center;
    }

}
