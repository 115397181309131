.navbar {
    background-color: #fbfafbd3;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 20;
  }
  
  .nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    max-width: 1500px;
    position: relative;
  }
  
  .nav-logo {
    color: #f5b921;
    align-items: center;
    margin-left: 30px;
    cursor: pointer;
    text-decoration: none;
    font-size: 30px;
    font-weight: bold;
    flex-grow: 1;
  
    display: flex;
    align-items: center;
    justify-items: center;
  }
  
  .nav-logo .icon {
    display: inline-block;
    width: 3rem;
    height: 3rem;
    margin-left: 16px;
  }
  
  .nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    margin-right: 2rem;
  }

  .nav-logo img{
    margin-top:10px;
    margin-bottom: 10px;
  
    width:70px;
    height:70px;
   
    
    
  }

  .nav-logo span{
    padding-left: 10px;
    color:rgb(134, 100, 14);
  }

 
  
  .nav-links {
    color: rgb(51, 2, 51);
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
  }
  /* .fa-code {
    margin-left: 1rem;
  } */
  
  .nav-item {
    line-height: 40px;
    margin-right: 1rem;
  }
  
  .nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
  }
  
  .nav-item:hover:after {
    width: 100%;
    background: rgb(51, 2, 51);
  }
  
  .nav-item .active {
    color: rgb(51, 2, 51);
    border: 1px solid rgb(51, 2, 51);
  }
  
  .nav-icon {
    display: none;
    color: #f5b921;
  }
  
  .nav-icon .icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
  }

  .nav-logogo{
    display: inline-block;
    width:10px;
    height:10px;
  }
  
  @media screen and (max-width: 960px) {
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-top: 1pxsolid #fff;
      position: absolute;
      top: 80px;
      left: -110%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: rgba(247, 243, 247, 0.94);
      padding:0px;
      margin:0px;
      left: 0px;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
    .nav-item .active {
      color: rgb(51, 2, 51);
      border: none;
    }
    .nav-links {
      padding: 1rem;
      padding-right: 0px;
      width: 100%;
      display: table;
    }
  
    .nav-logo .icon {
      width: 2rem;
      height: 2rem;
    }
  
    .nav-icon {
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-100%, -50%);
      width: 2rem;
      height: 2rem;
      cursor: pointer;
      color: rgb(51, 2, 51);
      margin-top: -15px;
      margin-right: -20px;
    }
  }

  .upper-links{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #6a1433;;
    padding:5px;
    
  }

  .phone-links{
    color:white;
    font-size:12px;
    font-weight:400;
  }
  .nav-social-media-wrap{
    gap:5px;
  }
  .social-icon{
    color:white;
    font-size:12px;
    padding-right:10px;
  }

  @media screen and (max-width: 460px){
    .upper-links{
        display:none;
    }
    .nav-logo span{
        display:none;
      }
    

  }
  